import "react-progress-2/main.css";
import "./assets/css/Dashboard.css";
import "./assets/css/Header.css";
import "./assets/css/Login.css";
import "./assets/css/MenuSideBar.css";
import "./assets/css/Responsive.css";
import "./assets/css/ThemeChange.css";

import Progress from "react-progress-2";
import { Route, Routes } from "react-router-dom";
import Layout from "./Layout";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import NotFount from "./errorPage/404";
import Complaints from "./pages/Complaints";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Transactions from "./pages/Transactions";
import Users from "./pages/Users";
import Blogs from "./pages/Blogs";
import Settings from "./pages/Settings";
import AddBlogs from "./components/Blogs/AddBlogs";
import EditBlogs from "./components/Blogs/EditBlogs";

function App() {
  return (
    <div className="App">
      <Progress.Component />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route path="/dashboard" element={<Dashboard />} />

          <Route path="/users" element={<Users />} />

          <Route path="/transactions" element={<Transactions />} />

          <Route path="/complaints" element={<Complaints />} />

          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/create" element={<AddBlogs />} />
          <Route path="/blogs/edit/:slug" element={<EditBlogs />} />

          <Route path="/settings" element={<Settings />} />
        </Route>

        <Route path="*" element={<NotFount />} />
      </Routes>
    </div>
  );
}

export default App;
