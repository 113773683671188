import { LeftOutlined } from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Button, Col, Form, Input, message, Row, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useBreadCrumb from "../../hooks/useBreadCrumb";
import { apis, ckeditorConfig, IMAGE_PATH } from "../../properties";
import axiosInstance from "../../util/axiosInstance";
import FileUpload from "../../util/FileUpload";

function EditBlogs() {
  const [form] = Form.useForm();
  const location = useLocation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const [desData, setDesData] = useState();
  const [upImage, setUpImage] = useState();
  const [blogId, setBlogId] = useState(0);

  useBreadCrumb("Create Blogs", location.pathname, "", "add");

  useEffect(() => {
    axiosInstance.get(apis.BLOGS + `/${slug}`).then((response) => {
      const res = response.data.data;
      form.setFieldsValue({
        title: res.title,
        slug: res.slug,
        seo_title: res.seo_title,
        seo_description: res.seo_description,
        seo_keywords: res.seo_keywords,
      });
      setDesData(res.description);
      setUpImage(res.image_path);
      setBlogId(res.id);
    });
    // eslint-disable-next-line
  }, []);

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  const submitForm = () => {
    if (upImage === undefined || upImage === "") {
      message.error("Please upload feature image");
      return;
    }
    form.validateFields().then((values) => {
      const data = {
        ...values,
        description: desData,
        image_path: upImage,
        slug: values.slug.trim(),
      };
      axiosInstance
        .put(apis.BLOGS + `/${blogId}`, data)
        .then((result) => {
          message.success(result.data.message);
        })
        .catch((error) => {
          message.error(error.response.data.message);
        });
    });
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("file", file);
            body.append("location", "ckeditor-images");
            fetch(`${apis.CKEDITOR_UPLOAD}`, {
              method: "post",
              body: body,
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({ default: `${IMAGE_PATH}/${res.data.file_path}` });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="com_head">
          <LeftOutlined onClick={() => navigate(-1)} />
          <p className="common_header">Create Blogs</p>
        </div>

        <Form
          autoComplete="off"
          form={form}
          onFinish={submitForm}
          onFinishFailed={onFinishFailed}
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Name cannot be empty!",
                  },
                ]}
              >
                <Input
                  type="text"
                  size="large"
                  placeholder="Blog Title"
                  onChange={(e) => {
                    form.setFieldsValue({
                      slug: convertToSlug(e.target.value),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Slug"
                name="slug"
                rules={[{ required: true, message: "Slug cannot be empty!" }]}
              >
                <Input type="text" size="large" placeholder="Slug" />
              </Form.Item>
              <Form.Item label="Descriptions">
                <CKEditor
                  config={{
                    extraPlugins: [uploadPlugin],
                    placeholder: "Content description",
                    toolbar: ckeditorConfig.toolbar,
                  }}
                  editor={ClassicEditor}
                  data={desData}
                  onReady={(editor) => {
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        "height",
                        "400px",
                        editor.editing.view.document.getRoot()
                      );
                      writer.setStyle(
                        "z-index",
                        "999999 !important",
                        editor.editing.view.document.getRoot()
                      );
                    });
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setDesData(data);
                  }}
                />
              </Form.Item>
              <Form.Item name="image" label="Upload Feature Images">
                <FileUpload
                  type="image"
                  location="blogs"
                  imageUpload={upImage}
                  setImageUpload={setUpImage}
                  accept="image/*"
                  prefix="blogs"
                />
              </Form.Item>
              <Divider plain>SEO Information</Divider>
              <Form.Item
                label="SEO title"
                name="seo_title"
                rules={[
                  {
                    required: true,
                    message: "SEO title cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="SEO title" />
              </Form.Item>
              <Form.Item
                label="SEO description"
                name="seo_description"
                rules={[
                  {
                    required: true,
                    message: "SEO description cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="SEO description" />
              </Form.Item>
              <Form.Item
                label="SEO keywords"
                name="seo_keywords"
                rules={[
                  {
                    required: true,
                    message: "SEO keywords cannot be empty!",
                  },
                ]}
              >
                <Input type="text" size="large" placeholder="SEO keywords" />
              </Form.Item>
            </Col>
          </Row>
          <div className="left_btn">
            <Button type="primary" className="primary__btn" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditBlogs;
