import { Button, message } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import UsersDataTable from "../components/Users/UsersDataTable";
import Progress from "react-progress-2";
import axiosInstance from "../util/axiosInstance";
import { IMAGE_PATH, apis } from "../properties";

export default function Users() {
  const location = useLocation();
  useBreadCrumb("Users", location.pathname, "Users");

  const downloadExcel = async (type, id) => {
    Progress.show();
    await axiosInstance
      .get(`${apis.DOWNLOAD_EXCEL}/${type}/${id}`)
      .then((result) => {
        let responseJson = result?.data;
        console.log(responseJson);
        if (responseJson.original.status === "success") {
          window.open(`${IMAGE_PATH}/${responseJson.original.data}`, "_blank");
          Progress.hide();
        } else {
          message.error(responseJson.original.message);
          Progress.hide();
        }
      });
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => downloadExcel("user-all", "all")}
          >
            Download All Users
          </Button>
        </div>
        <UsersDataTable />
      </div>
    </div>
  );
}
