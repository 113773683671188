import Swal from "sweetalert2";

export const LIVE_MAIN_API_URL = process.env.REACT_APP_API_URL;
export const IMAGE_PATH = process.env.REACT_APP_IMAGEKIT_URL;

export const BASE_API_URL = `${LIVE_MAIN_API_URL}`;

export const apis = {
  ADMIN_LOGIN: `${BASE_API_URL}api/v1/admin/login`,

  USERS: `${BASE_API_URL}api/v1/admin/users`,
  ADMIN_RESET_PW: `${BASE_API_URL}api/v1/admin/reset-pw`,
  ADMIN_MANUAL_PAY: `${BASE_API_URL}api/v1/admin/manual-pay`,
  DOWNLOAD_EXCEL: `${BASE_API_URL}api/v1/download-excel`,
	TRANSACTIONS: `${BASE_API_URL}api/v1/admin/transactions`,
  COMPLAINS: `${BASE_API_URL}api/v1/admin/complaints`,
	GET_DASHBOARD_DATA: `${BASE_API_URL}api/v1/admin/dashboard-data`,

	BLOGS: `${BASE_API_URL}api/v1/admin/blog`,
	NUMBER_CHANGE: `${BASE_API_URL}api/get-number`,

  FILE_UPLOAD: `${BASE_API_URL}api/file-upload`,
  FILE_DELETE: `${BASE_API_URL}api/file-delete`,
  CKEDITOR_UPLOAD: `${BASE_API_URL}api/ckeditor-upload`,
};

// export const S3config = {
//   bucketName: "adsindian",
//   dirName: "DesiPlayBoys" /* optional */,
//   region: "ap-south-1",
//   accessKeyId: "AKIAUD2NEDNBHALOWRGG",
//   secretAccessKey: "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",
//   s3Url: "https://adsindian.s3.ap-south-1.amazonaws.com" /* optional */,
// };

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

export const ckeditorConfig = {
  toolbar: [
    "heading",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "|",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "imageUpload",
    "imageStyle:full",
    "imageStyle:side",
    "|",
    "code",
    "CodeBlock",
    "|",
  ],
};
