import { Table, Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useAllTransactions from "../../hooks/useAllTransactions";

function TransactionsDataTable() {
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const allTransactions = useAllTransactions(filterData);

  const columns = [
    {
      title: "Pay Date",
      render: (record) =>
        moment(record?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      width: 200,
    },
    {
      title: "Transaction No",
      render: (record) => record?.txnid,
      width: 200,
    },
    {
      title: "User Name",
      render: (record) => record?.user_name,
      width: 300,
    },
    {
      title: "Email",
      render: (record) => record?.email,
      width: 200,
    },
    {
      title: "Amount",
      render: (record) => record?.amount.toFixed(2),
      width: 100,
    },
    {
      title: "Status",
      render: (record) => {
        if (record.status === 1) {
          return (
            <Tag className="tags" color="#43a08b">
              Success
            </Tag>
          );
        } else if (record.status === 0) {
          return (
            <Tag className="tags" color="#df0000">
              Fail
            </Tag>
          );
        } else {
          return (
            <Tag className="tags" color="#df0000">
              NA
            </Tag>
          );
        }
      },
      width: 100,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={allTransactions.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allTransactions.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default TransactionsDataTable;
