import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Button, Table, Tag, Tooltip, Modal, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useAllBlogs from "../../hooks/useAllBlogs";
import axiosInstance from "../../util/axiosInstance";
import { apis } from "../../properties";
import Progress from "react-progress-2";
import { useRefreshTable } from "../../store";
import { useNavigate } from "react-router-dom";

const { confirm } = Modal;

function BlogsDataTable() {
  const { refreshTable, setRefreshTable } = useRefreshTable();
  const navigate = useNavigate();

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const allBlogs = useAllBlogs(filterData);

  const columns = [
    {
      title: "Created Date",
      render: (record) =>
        moment(record?.createdAt).format("YYYY-MM-DD hh:mm:ss a"),
      width: 200,
    },
    {
      title: "Title",
      render: (record) => {
        return record?.title.length > 50
          ? record?.title.substring(0, 50) + "..."
          : record?.title;
      },
    },
    {
      title: "Status",
      render: (record) => {
        if (record?.status === "ACTIVE") {
          return (
            <Tag className="tags" color="#43a08b">
              ACTIVE
            </Tag>
          );
        } else {
          return (
            <Tag className="tags" color="#df0000">
              INACTIVE
            </Tag>
          );
        }
      },
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="View">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                navigate(`/blogs/edit/${record.slug}`);
              }}
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record?.status === "ACTIVE" ? (
            <Tooltip placement="bottom" title="INACTIVE">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => statusChange(record.id, "INACTIVE")}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="ACTIVE">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => statusChange(record.id, "ACTIVE")}
              />
            </Tooltip>
          )}
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="View">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteBlog(record.id)}
            />
          </Tooltip>
        </>
      ),
      fixed: "right",
      width: 200,
      align: "right",
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        axiosInstance
          .put(`${apis.BLOGS}/${id}`, {
            status: type,
          })
          .then((result) => {
            let responseJson = result;
            setRefreshTable(!refreshTable);
            message.success(responseJson.data.message);
            Progress.hide();
          })
          .catch((error) => {
            message.error(error.response.data.message);
            Progress.hide();
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const deleteBlog = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to delete this?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        axiosInstance
          .delete(`${apis.BLOGS}/${id}`)
          .then((result) => {
            let responseJson = result;
            setRefreshTable(!refreshTable);
            message.success(responseJson.data.message);
            Progress.hide();
          })
          .catch((error) => {
            message.error(error.response.data.message);
            Progress.hide();
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={allBlogs.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allBlogs.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default BlogsDataTable;
