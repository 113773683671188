export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard",
    iconClassName: "bi bi-cast",
    route_key: "dashboard",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Users List",
    exact: true,
    to: "/users",
    iconClassName: "bi bi-people",
    route_key: "users",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Transactions",
    exact: true,
    to: "/transactions",
    iconClassName: "bi bi-wallet",
    route_key: "transactions",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Complaints",
    exact: true,
    to: "/complaints",
    iconClassName: "bi bi-archive",
    route_key: "complaints",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Blogs",
    exact: true,
    to: "/blogs",
    iconClassName: "bi bi-clipboard-data",
    route_key: "blogs",
    privilege: ["SUPER_ADMIN"],
  },
  {
    name: "Settings",
    exact: true,
    to: "/settings",
    iconClassName: "bi bi-gear",
    route_key: "settings",
    privilege: ["SUPER_ADMIN"],
  },
];
