import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashBoardBox from "../components/DashBoardWidgets/DashBoardBox";
import useBreadCrumb from "../hooks/useBreadCrumb";
import axiosInstance from "../util/axiosInstance";
import { apis } from "../properties";

function Dashboard() {
  const location = useLocation();
  useBreadCrumb("Dashboard", location.pathname, "Dashboard");

  const [mainData, setMainData] = useState();

  useEffect(() => {
    dashboardData();
    // eslint-disable-next-line
  }, []);

  const dashboardData = async () => {
    axiosInstance.get(apis.GET_DASHBOARD_DATA).then((result) => {
      let responseJson = result.data;
      console.log(responseJson);
      if (responseJson.status === "success") {
        setMainData(responseJson.data);
      }
    });
  };

  return (
    <div className="dashboard">
      <div className="box_section">
        <DashBoardBox
          title="Total User profiles"
          count={mainData ? mainData.totalUsers : 0}
          icon={"bi bi-person-bounding-box"}
        />
        <DashBoardBox
          title="Total Transactions"
          count={mainData ? mainData.totalTransactions : 0}
          icon={"bi bi-currency-dollar"}
        />
        <DashBoardBox
          title="Total Subscriptions"
          count={mainData ? mainData.totalSubscription : 0}
          icon={"bi bi-handbag"}
        />
        <DashBoardBox
          title="Total Complaints"
          count={mainData ? mainData.totalComplaint : 0}
          icon={"bi bi-hdd-stack"}
        />
      </div>
    </div>
  );
}

export default Dashboard;
