import React from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import TransactionsDataTable from "../components/Transactions/TransactionsDataTable";

export default function Transactions() {
  const location = useLocation();
  useBreadCrumb("Transactions", location.pathname, "Transactions");

  return (
    <div className="dashboard">
      <div className="section_row">
        <TransactionsDataTable />
      </div>
    </div>
  );
}
