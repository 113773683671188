import React from "react";
import { useLocation } from "react-router-dom";
import ComplaintsDataTable from "../components/Complaints/ComplaintsDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";

export default function Complaints() {
  const location = useLocation();
  useBreadCrumb("Complaints", location.pathname, "Complaints");

  return (
    <div className="dashboard">
      <div className="section_row">
        <ComplaintsDataTable />
      </div>
    </div>
  );
}
