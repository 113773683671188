import { Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import useAllComplaints from "../../hooks/useAllComplaints";

function ComplaintsDataTable() {
  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const allComplaints = useAllComplaints(filterData);

  const columns = [
    {
      title: "Pay Date",
      render: (record) =>
        moment(record?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      width: 200,
    },
    {
      title: "Transaction No",
      render: (record) => record?.name,
      width: 200,
    },
    {
      title: "User Name",
      render: (record) => record?.email,
      width: 200,
    },
    {
      title: "Email",
      render: (record) => record?.contact,
      width: 200,
    },
    {
      title: "Amount",
      render: (record) => record?.message,
      width: 300,
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={allComplaints.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allComplaints.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default ComplaintsDataTable;
