import React from "react";
import { useLocation } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import ChangeNumber from "../components/Settings/ChangeNumber";

export default function Settings() {
  const location = useLocation();
  useBreadCrumb("Settings", location.pathname, "Settings");

  return (
    <div className="dashboard">
      <div className="section_row">
        <ChangeNumber />
      </div>
    </div>
  );
}
