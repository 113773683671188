import {
  CheckOutlined,
  CloseOutlined,
  DollarOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
} from "@ant-design/icons";
import { Button, Modal, Table, Tag, Tooltip, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Progress from "react-progress-2";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import axiosInstance from "../../util/axiosInstance";
import useAllUsers from "../../hooks/useAllUsers";
import Swal from "sweetalert2";

const { confirm } = Modal;

function UsersDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const [filterData, setFilterData] = useState({
    page: 1,
    pageSize: 10,
  });

  const allUsers = useAllUsers(filterData);

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
  }

  const columns = [
    {
      title: "Join Date",
      render: (record) =>
        moment(record?.created_at).format("YYYY-MM-DD hh:mm:ss a"),
      width: 200,
    },
    {
      title: "Last Updated Date",
      render: (record) =>
        moment(record?.updated_at).format("YYYY-MM-DD hh:mm:ss a"),
      width: 200,
    },
    {
      title: "User Name",
      render: (record) => record?.display_name,
      width: 200,
    },
    {
      title: "Email",
      render: (record) => record?.email,
      width: 300,
    },
    {
      title: "Contact Whatsapp",
      render: (record) => record?.contact_whatsup,
      width: 200,
    },
    {
      title: "Contact Number",
      render: (record) => record?.contact,
      width: 200,
    },
    {
      title: "User Role",
      render: (record) => {
        if (record?.role === "Male-Escort") {
          return (
            <Tag className="tags" color="#c73b27">
              {record.role}
            </Tag>
          );
        } else {
          return (
            <Tag className="tags" color="#df0000">
              {record.role}
            </Tag>
          );
        }
      },
      width: 200,
    },
    {
      title: "Subscription Plan",
      render: (record) => {
        if (
          record?.subscription_plan === "PREMIUM_Client" ||
          record?.subscription_plan === "PREMIUM_MaleEscort"
        ) {
          return (
            <Tag className="tags" color="#3498db">
              {record.subscription_plan}
            </Tag>
          );
        } else {
          return (
            <Tag className="tags" color="#34495e">
              {record.subscription_plan}
            </Tag>
          );
        }
      },
      width: 200,
    },
    {
      title: "Status",
      render: (record) => {
        if (record.user_detail_status === 1) {
          return (
            <Tag className="tags" color="#43a08b">
              Active
            </Tag>
          );
        } else if (record.user_detail_status === 2) {
          return (
            <Tag className="tags" color="#df6c00">
              Updated
            </Tag>
          );
        } else {
          return (
            <Tag className="tags" color="#df0000">
              Inactive
            </Tag>
          );
        }
      },
      fixed: "right",
      width: 100,
    },
    {
      title: "Action",
      render: (text, record) => (
        <>
          <Tooltip placement="bottom" title="View">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => {
                const url = `https://desiplayboys.in/profile-page/${convertToSlug(
                  record.display_name
                )}/${record.profile_id}`;
                window.open(url, "_blank").focus();
              }}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Manual Pay">
            <Button
              className="edit_button"
              shape="circle"
              icon={<DollarOutlined />}
              onClick={() => manualPay(record.id)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          <Tooltip placement="bottom" title="Password Change">
            <Button
              className="edit_button"
              shape="circle"
              icon={<KeyOutlined />}
              onClick={() => passwordReset(record.id)}
            />
          </Tooltip>
          &nbsp;&nbsp;
          {record.user_detail_status === 1 ? (
            <Tooltip placement="bottom" title="INACTIVE">
              <Button
                className="delete_button"
                shape="circle"
                icon={<CloseOutlined />}
                onClick={() => statusChange(record.id, "INACTIVE")}
              />
            </Tooltip>
          ) : (
            <Tooltip placement="bottom" title="ACTIVE">
              <Button
                className="view_button"
                shape="circle"
                icon={<CheckOutlined />}
                onClick={() => statusChange(record.id, "ACTIVE")}
              />
            </Tooltip>
          )}
        </>
      ),
      fixed: "right",
      width: 200,
      align: "right",
    },
  ];

  const statusChange = (id, type) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do  you want to ${type} this?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        axiosInstance
          .put(`${apis.USERS}/${id}`, {
            status: type,
          })
          .then((result) => {
            let responseJson = result;
            setRefreshTable(!refreshTable);
            message.success(responseJson.data.message);
            Progress.hide();
          })
          .catch((error) => {
            message.error(error.response.data.message);
            Progress.hide();
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const passwordReset = (id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `You want to reset the password`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        axiosInstance
          .post(apis.ADMIN_RESET_PW, { id: id })
          .then((result) => {
            let responseJson = result;
            console.log(responseJson);
            setRefreshTable(!refreshTable);
            Progress.hide();
            message.success(
              "Password reset successful. Default password was Test@123."
            );
          })
          .catch((error) => {
            message.error(error.response.data.message);
            Progress.hide();
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const manualPay = async (id) => {
    const { value: amount } = await Swal.fire({
      title: "Payment for User",
      input: "text",
      inputLabel: "Enter Amount in Rs (in numbers)",
      inputPlaceholder: "Enter amount",
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });
    if (amount) {
      Progress.show();
      axiosInstance
        .post(apis.ADMIN_MANUAL_PAY, { userID: id, amount: amount })
        .then((result) => {
          let responseJson = result;
          if (responseJson.status === "success") {
            Progress.hide();
            Swal.fire(
              "Successful!",
              "Payment has been done successfully",
              "success"
            );
          } else if (responseJson.status === "error") {
            message.error(responseJson.message);
            Progress.hide();
          }
        });
    }
  };

  return (
    <>
      <Table
        scroll={{ x: 2000 }}
        columns={columns}
        dataSource={allUsers.data}
        bordered
        pagination={{
          current: filterData.current_page,
          pageSize: filterData.pageSize,
          total: allUsers.total,
          onChange: (page, pageSize) => {
            setFilterData({
              ...filterData,
              page: page,
              pageSize: pageSize,
            });
          },
          showSizeChanger: true,
          showTotal: (total) => `Total ${total} items`,
        }}
      />
    </>
  );
}

export default UsersDataTable;
