import { Button, Form, Input, message } from "antd";
import React, { useEffect } from "react";
import { apis } from "../../properties";
import axiosInstance from "../../util/axiosInstance";

const ChangeNumber = () => {
  const [form] = Form.useForm();

  const onFinishFailed = () => {
    message.error("Please fill all the details");
  };

  useEffect(() => {
    axiosInstance.get(apis.NUMBER_CHANGE).then((response) => {
      const res = response.data.data;
      form.setFieldsValue({
        contact: res,
      });
    });
    // eslint-disable-next-line
  }, []);

  const submitForm = () => {
    form.validateFields().then((values) => {
      const data = {
        ...values,
      };
      axiosInstance
        .put(apis.NUMBER_CHANGE, data)
        .then((result) => {
          message.success(result.data.message);
        })
        .catch((error) => {
          message.error(error.response.data.message);
        });
    });
  };

  return (
    <>
      <div className="com_head">
        <p className="common_header">Change whatsapp number</p>
      </div>
      <Form
        autoComplete="off"
        form={form}
        onFinish={submitForm}
        onFinishFailed={onFinishFailed}
        initialValues={{ remember: true }}
        layout="vertical"
      >
        <Form.Item
          label="Whatsapp Number"
          name="contact"
          rules={[
            {
              required: true,
              message: "Name cannot be empty!",
            },
            {
                pattern: new RegExp("^[0-9]{12}$"),
                message: "Enter a valid number",
            }
          ]}
        >
          <Input type="text" size="large" placeholder="Whatsapp Number" />
        </Form.Item>
        <div className="left_btn">
          <Button type="primary" className="primary__btn" htmlType="submit">
            Submit
          </Button>
        </div>
      </Form>
    </>
  );
};

export default ChangeNumber;
