import { Button } from "antd";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import BlogsDataTable from "../components/Blogs/BlogsDataTable";

export default function Blogs() {
  const location = useLocation();
  useBreadCrumb("Blogs", location.pathname, "Blogs");
  const navigate = useNavigate();

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate("/blogs/create")}
          >
            Add New Blog
          </Button>
        </div>
        <BlogsDataTable />
      </div>
    </div>
  );
}
